import { registerSW } from './registerSW';

export async function redirect(brand: string | null) {
	console.log('trying to redirect');
	if ((await navigator.serviceWorker.getRegistrations())?.length > 0) {
		const params = new URLSearchParams(window.location.search);
		const orientation = params.get('orientation');
		const prefersReducedMotion = params.get('prefers-reduced-motion');
		const apk = params.get('apk') === 'true';
		const apkVersion = params.get('version');
		const chromebox =
			params.get('chromebox') === 'true' ||
			window.location.href.includes('chrome');
		const deviceType = params.get('device-type');

		const url = new URL(`${window.location.origin}/${brand}/index.html`);
		if (orientation) url.searchParams.set('orientation', orientation);
		if (apk) url.searchParams.set('apk', 'true');
		if (chromebox) url.searchParams.set('chromebox', 'true');
		if (prefersReducedMotion) {
			url.searchParams.set(
				'prefers-reduced-motion',
				prefersReducedMotion
			);
		}
		if (apkVersion) url.searchParams.set('version', apkVersion);
		if (deviceType) url.searchParams.set('device-type', deviceType);

		if (brand === 'audi') {
			window.dispatchEvent(new CustomEvent('redirect-label-audi'));
		}
		if (brand === 'vw') {
			window.dispatchEvent(new CustomEvent('redirect-label-vw'));
		}

		window.location.replace(url);
	} else {
		registerSW();
		setTimeout(() => redirect(brand), 500);
	}
}
